 .namuna{ 
    color: #9BB7D4;
    color: #1B7340;
    color: #E9897E;
    color: #0072B5;
    color: #FDAC53;
    color: #B55A30;
    color: #A0DAA9;
    color: #F19828;
    color: #D2386C;
    color: #926AA6; 
  }

body {
  padding: 130px 0 0 0;
  box-sizing: border-box;
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161818;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;
}


.map-container {
  width: 100%;
  height: 400px;
  position: relative;
  top: 50px;
}